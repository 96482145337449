<template>
    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-row class="yj-form">            
                    <a-col :span="24">
                        <label>名称 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 v-model="name"
                                 placeholder="请输入模板消息名称"
                                 ></a-input>
                        <a-button type="primary" @click="getTableData">查询</a-button>
                    </a-col>
                </a-row>
                <a-table bordered :columns="columns"
                         rowKey="UserID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img">
                    <span slot="action" slot-scope="text, record">
                        <a v-if="record.IsOpen" @click="openMessage(record,2)">关闭</a>                        
                        <a v-if="record.IsOpen==false" @click="openMessage(record,1)">开启</a>               
                    </span>

                </a-table>
            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import http from "@/Plugin/Http.js";
    //import util from "@/Plugin/util.js";
    export default {
        name: "TemplateMessage_List",
        data() {
            return {         
                columns: [
                    {
                        title: "ID",
                        dataIndex: "ID",
                        key: "ID",
                        align: "center",                      
                        width: 50
                    },
                    {
                        title: "模板名称",
                        dataIndex: "TemplateName",
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: "编辑",
                        width: 50,
                        scopedSlots: { customRender: 'action' },                  
                    }
                ],
                category: "",
                tableData: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                }
            };
        },
        methods: {
            openMessage: function (item, type) {       
                var self = this;
                var desc = "是否开启该模板消息";
                var url = "/ShopModule/TemplateMessage/OpenMessage";
                if (type == 2) {
                    url = "/ShopModule/TemplateMessage/RemoveMessage";
                    desc = "是否关闭该模板消息";
                }

                this.$confirm({
                    title: "提示",
                    content: desc,
                    okText: "确定",
                    cancelText: "取消",
                    onOk() {
                        var op = {
                            url: url,
                            data: {
                                id: item.ID
                            },
                            OnSuccess: function (data) {
                                var d1 = data;
                                console.log(d1);
                                if (data.data.Result) {                                   
                                    self.getTableData();
                                } else {
                                    self.$message.error(data.data.Message);
                                }
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });



          
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/TemplateMessage/GetWechatTemplateMessage",
                    data: {
                        pageIndex: self.pagination.current,                     
                        name: self.name
                    },
                    OnSuccess: function (data) {
                        
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {

            this.getTableData();
        },
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>